import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'

export const toStartDate = (dateJS: Dayjs) => {
  const dateObj = dateJS.startOf('day').toDate()
  dateObj.toJSON = function () {
    // problem is that default to JSON.stringify() method prints UTC time, but doesn't print Time Zone
    return dayjs(this).format('YYYY-MM-DD[T]HH:mm:ss.SSS')
  }
  return dateObj
}

export const toEndDate = (dateJS: Dayjs) => {
  const dateObj = dateJS.endOf('day').toDate()
  dateObj.toJSON = function () {
    // problem is that default to JSON.stringify() method prints UTC time, but doesn't print Time Zone
    return dayjs(this).format('YYYY-MM-DD[T]HH:mm:ss.SSS')
  }
  return dateObj
}

export const usePickDates = () => {
  const [startDate, setStartDate] = useState(toStartDate(dayjs()))
  const [endDate, setEndDate] = useState(toEndDate(dayjs()))

  const setDates = (startDate:Date, endDate:Date) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return { startDate, endDate, setDates }
}
