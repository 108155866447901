import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'
import * as React from 'react'

import { CustomButton } from 'components/customButton/CustomButton'

interface Props {
  id: number
  handleDelete: (e: any) => void
  entityName: string
}

export const DeleteButtonPopConfirm: React.FC<Props> = ({ id, handleDelete, entityName }) => {
  const popconfirmText = `Are you sure you want to delete this ${entityName}?`

  return (
    <Popconfirm
      title={popconfirmText}
      okText="Yes"
      cancelText="No"
      onConfirm={() => {
        handleDelete(id)
      }}
    >
      <Tooltip title={`Delete ${entityName}`}>
        <CustomButton type="warning" icon={<DeleteOutlined />} btnType="button">
          Delete
        </CustomButton>
      </Tooltip>
    </Popconfirm>
  )
}
