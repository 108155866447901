import { Alert, Form, Input, message, Modal, Select, Space } from 'antd'
import * as React from 'react'

import { accountApi } from 'api/accountApi'

import { CustomButton } from 'components/customButton/CustomButton'

import 'components/contact-support.scss'

interface SupportForm {
  issueType: string
  description: string
}

interface Props {
  email?: string
  modalIsVisible: boolean
  setModalIsVisible: (val: boolean) => void
}

export const ContactSupportModal: React.FC<Props> = ({ email, modalIsVisible, setModalIsVisible }) => {
  const [form] = Form.useForm()

  const handleOk = () => {
    form.validateFields().then((values: SupportForm) => {
      form.resetFields()
      doSendForm(values.issueType, values.description)
      setModalIsVisible(false)
      message.success('Your support request has been sent')
    })
  }

  const doSendForm = (issueType: string, description: string) => {
    accountApi.sendSupportRequest(issueType, description)
  }

  return (
    <div className="contact">
      <Modal
        title="Contact Customer Support"
        centered
        open={modalIsVisible}
        onOk={() => setModalIsVisible(false)}
        onCancel={() => setModalIsVisible(false)}
        footer={[
          <Space>
            <CustomButton key="Cancel" type="bordered" onClick={() => setModalIsVisible(false)}>
              Cancel
            </CustomButton>

            <CustomButton key="submit" type="primary" onClick={handleOk}>
              Submit
            </CustomButton>
          </Space>,
        ]}
      >
        <div className="contact__alert">
          <Alert
            message=" If you're experiencing any problems or have
                                    questions, please full in the form below an
                                    we'll get back to you."
            type="info"
            showIcon
          />
        </div>

        <Form
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          onFinish={handleOk}
          colon={false}
        >
          <Form.Item label="Email">
            <Input placeholder={email} disabled />
          </Form.Item>

          <Form.Item
            name="issueType"
            label="Type of Issue"
            rules={[{ required: true, message: 'Please select at leas one issue' }]}
            required
          >
            <Select placeholder="Please select one option">
              <Select.Option value="productQuestion">Product question</Select.Option>
              <Select.Option value="setupProblem">Setup help</Select.Option>
              <Select.Option value="techIssue">Report technical issue</Select.Option>
              <Select.Option value="billingIssue">Billing or credit issue</Select.Option>
              <Select.Option value="other">Other</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please describe your problem' }]}
            required
          >
            <Input.TextArea placeholder="How we can help?" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
