import { message, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { alertConfigApi } from 'api/alertConfigApi'
import { alertNotificationApi } from 'api/alertNotificationApi'
import { AlertConfigBean, AlertNotificationBean } from 'api/base/api'

import { ALERT_PATH } from 'constants/routes'

import { Error } from 'components/globalError'

import { AlertNotificationForm } from 'pages/alertConfig/AlertNotificationForm'

import 'pages/alertConfig/alertPage.scss'

interface Props {
  id?: number
}

export const AlertNotificationPage: React.FC<Props> = ({ id }) => {
  const [alertNotification, setAlertNotification] = useState<AlertNotificationBean | null>(null)
  const [alertConfigs, setAlertConfigs] = useState<AlertConfigBean[] | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  const [errorMessage, setErrorMessage] = useState(
    `Alert Notification Load Error. 
        Please refresh the page or try again later. 
        If the problem persists, please contact Customer Support.`,
  )

  const loadNotificationBean = (id: number) => {
    setIsLoading(true)
    alertNotificationApi.getOne(id).then(
      (response) => {
        setAlertNotification(response.data)
        setIsLoading(false)
        setShowError(false)
      },
      () => {
        setIsLoading(false)
        setShowError(true)
        setErrorMessage('Alert Notification load error')
      },
    )
  }

  const loadConfigInfo = () => {
    setIsLoading(true)
    alertConfigApi.getAllAlertConfig().then(
      (response) => {
        setAlertConfigs(response)
        setIsLoading(false)
        setShowError(false)
      },
      () => {
        setIsLoading(false)
        setShowError(true)
        setErrorMessage('Alert Config load error')
      },
    )
  }

  const history = useHistory()

  const createAlertNotification = (form: any) => {
    alertNotificationApi
      .create(form)
      .then(() => {
        message.success('Alert Notification added')
        window.prebidmanagerUiLibFiltersData = null
        history.push(`${ALERT_PATH}?notification`)
      })
      .catch((err) => {
        setErrorMessage(err)
        setShowError(true)
      })
  }

  const updateAlertNotification = (form: AlertNotificationBean) => {
    const updateForm: AlertNotificationBean = {
      id: form.id as number,
      name: form.name as string,
      alertConfigIds: form.alertConfigIds as number[],
      active: form.active as boolean,
      emails: form.emails as string,
    }

    alertNotificationApi.update(id as number, updateForm).then(
      () => {
        setIsLoading(false)
        message.success('Alert Notification changes saved')
        window.prebidmanagerUiLibFiltersData = null
        history.push(`${ALERT_PATH}?notification`)
      },
      () => {
        setIsLoading(false)
        setShowError(true)
        setErrorMessage(
          `Alert Notification update failed. 
                    Please try again. 
                    If the problem persists, please contact Customer Support.`,
        )
      },
    )
  }
  const handleDelete = (id: number) => {
    alertNotificationApi.delete(id).then(() => {
      message.warning('Alert Notification is deleted')
      window.prebidmanagerUiLibFiltersData = null
      history.push(`${ALERT_PATH}?notification`)
    })
  }

  useEffect(() => {
    loadConfigInfo()
    if (id) {
      loadNotificationBean(id)
    }
  }, [id])

  return (
    <div className="alert-page">
      <Error showError={showError} message={errorMessage} closable isAuthorised />
      <div className="alert-page__main">
        <Spin spinning={isLoading}>
          <div className="config-alert-form__title">
            {id ? <Title level={4}>Edit Notification</Title> : <Title level={4}>Add New Notification</Title>}
          </div>
          {!id ? (
            <AlertNotificationForm
              alertConfigs={alertConfigs as AlertConfigBean[]}
              submitButtonName="Add Alert Notification"
              handleSubmit={createAlertNotification}
            />
          ) : (
            <AlertNotificationForm
              alertConfigs={alertConfigs as AlertConfigBean[]}
              alertNotificationBean={alertNotification as AlertNotificationBean}
              submitButtonName="Save"
              showArchiveButton
              handleSubmit={updateAlertNotification}
              handleDelete={handleDelete}
            />
          )}
        </Spin>
      </div>
    </div>
  )
}
