import * as ProductionCharts from '@adend/asteriobid-ui-lib'
import * as TestingCharts from '@adend/asteriobid-ui-lib-testing'
import { Col, Row, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'

import { accountApi } from 'api/accountApi'
import { alertApi } from 'api/alertApi.ts'
import { ProfileBean } from 'api/base/api'

import * as routes from 'constants/routes'

import { env } from 'env/index'

import { Body, BottomNav, MainContainer, PageContent, ProgressScreen, Sidebar, TopNav } from 'layout/index'

import { globalErrorsStore } from 'store/GlobalErrorsStore'
import { loginStore } from 'store/LoginStore'

import { colorPaletteManager } from 'utils/colorPalette'
import { useMinWidthBreakpoint } from 'utils/useMinWidthBreakpoint'
import { UserContext } from 'utils/userContext'
import { userUpdateStore } from 'utils/userUpdateStore'

import { Errors } from 'components/globalError/Error'

import { AdUnitIndexComponent } from 'pages/adunit'
import { AdUnitList } from 'pages/adunit/AdUnitList'
import { AlertConfigIndexComponent } from 'pages/alertConfig/AlertConfigIndex'
import { AlertNotificationIndexComponent } from 'pages/alertConfig/AlertNotificationIndex'
import { Alerts } from 'pages/alertConfig/Alerts'
import { ConfigIndexComponent } from 'pages/config'
import { SiteList } from 'pages/config/SiteList.tsx'
import { ErrorPage } from 'pages/error/ErrorPage'
import { LoginScreen } from 'pages/login'
import { PaymentsScreen } from 'pages/payments'
import { ProfileScreen } from 'pages/profile'
import {
  EmailConfirmationExpiredCodeScreen,
  EmailConfirmationSuccessScreen,
  EmailConfirmationUnknownCodeScreen,
  Registration,
} from 'pages/registration'
import {
  ResetPassword,
  ResetPasswordExpiredScreen,
  ResetPasswordSuccessScreen,
  ResetPasswordUnknownToken,
  RestorePassword,
  RestorePasswordEmailSentScreen,
} from 'pages/resetpassword'
import { TermsAsPage, TermsPage } from 'pages/terms'

import 'src/main.scss'

if (env.library === 'production') {
  import('@adend/asteriobid-ui-lib/dist/index.css')
}

if (env.library === 'testing') {
  import('@adend/asteriobid-ui-lib-testing/dist/index.css')
}

colorPaletteManager.reset()

export const App = observer(() => {
  const [profile, setProfile] = useState<ProfileBean | null>(null)

  const isMobile = useMinWidthBreakpoint('992px')
  const [menuCollapsed, setMenuCollapsed] = useState(!isMobile)

  const [backendInError, setBackendInError] = useState(false)

  const isAuthorized = !!loginStore.accessToken?.length
  const { isColorPaletteLoading } = useContext(UserContext)

  useEffect(() => {
    userUpdateStore.updateInfo()
  }, [isAuthorized])

  const location = useLocation()
  const history = useHistory()

  const [selectedMenuKeys, setSelectedMenuKeys] = useState([''])

  useEffect(() => {
    setSelectedMenuKeys([location.pathname.substring(1)])
  }, [location])

  const redirectToTargetPage = () => {
    const stateFromRedirect = location.state as { redirectedFrom?: string }
    const redirectedFrom = stateFromRedirect?.redirectedFrom
    if (redirectedFrom && redirectedFrom !== '/logout') {
      history.push(redirectedFrom)
    }
  }

  const loginByAccessToken = () => {
    const urlParams = new URLSearchParams(location.search)
    const token = urlParams.get('token')

    if (token) {
      loginStore.login(token, 'isFromAdminDashboard')
      redirectToTargetPage()
    }
  }

  const loadProfile = () => {
    accountApi.load().then(
      (response) => (response ? setProfile(response) : setBackendInError(true)),
      (err) => {
        globalErrorsStore.addError(err)
        accountApi.logout()
      },
    )
  }

  const [alertCount, setAlertCount] = useState<number>(0)

  const updateNewAlertCounter = () => {
    alertApi.getTodayAlertCount().then((resp) => {
      setAlertCount(resp)
    })
  }

  useEffect(
    loginByAccessToken,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  useEffect(() => {
    loadProfile()
  }, [isAuthorized, backendInError])

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateNewAlertCounter()
    }, 300000)
    return () => clearInterval(intervalId)
  }, [])

  return !isAuthorized ? (
    <Body>
      <Switch>
        <Route path="/login" component={LoginScreen} />
        <Route path={routes.REGISTRATION_PATH} component={Registration} />
        <Route path={routes.EMAIL_CONFIRMATION_SUCCESS_PATH} component={EmailConfirmationSuccessScreen} />
        <Route path={routes.EMAIL_CONFIRMATION_UNKNOWN_CODE_PATH} component={EmailConfirmationUnknownCodeScreen} />
        <Route path={routes.EMAIL_CONFIRMATION_EXPIRED_CODE_PATH} component={EmailConfirmationExpiredCodeScreen} />
        <Route path={routes.RESTORE_PATH} component={RestorePassword} />
        <Route path={routes.RESET_PASSWORD_PATH} component={ResetPassword} />
        <Route path={routes.RESTORE_PASSWORD_EMAIL_SENT_PATH} component={RestorePasswordEmailSentScreen} />
        <Route path={routes.RESET_PASSWORD_SUCCESS_PATH} component={ResetPasswordSuccessScreen} />
        <Route path={routes.RESET_PASSWORD_EXPIRED_PATH} component={ResetPasswordExpiredScreen} />
        <Route path={routes.RESET_PASSWORD_UNKNOWN_TOKEN_PATH} component={ResetPasswordUnknownToken} />
        <Route path={routes.TERMS_OF_SERVICE_PATH} component={TermsAsPage} />
        <Redirect from="/" to={routes.LOGIN_PATH} />
      </Switch>
    </Body>
  ) : isColorPaletteLoading ? (
    <Row style={{ height: '100vh' }} justify="space-around" align="middle">
      <Col>
        <Spin size="large" />
      </Col>
    </Row>
  ) : (
    <Body>
      {!profile ? (
        !backendInError ? (
          <ProgressScreen />
        ) : (
          <ErrorPage tryAgain={() => setBackendInError(false)} />
        )
      ) : (
        <>
          <Sidebar
            selectedKeys={selectedMenuKeys}
            email={profile.email}
            collapsed={menuCollapsed}
            alertCount={alertCount}
          />
          <MainContainer>
            <TopNav onClick={() => setMenuCollapsed(!menuCollapsed)} collapsed={menuCollapsed} profile={profile} />
            <PageContent>
              <Errors profile={profile} />
              {/* <Spin tip="Loading" spinning={!profile}> */}
              {/* true <->  profile?.cardEntered */}
              {profile?.cardEntered || loginStore.isFromAdminDashboard ? (
                <Switch>
                  <Route
                    path={routes.DASHBOARD_PATH}
                    render={() =>
                      env.library === 'production' ? (
                        <ProductionCharts.TotalDashboard
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      ) : (
                        <TestingCharts.TotalDashboard
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      )
                    }
                  />
                  <Route
                    path={routes.BIDDERS_PATH}
                    render={() =>
                      env.library === 'production' ? (
                        <ProductionCharts.BiddersComponent
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      ) : (
                        <TestingCharts.BiddersComponent
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      )
                    }
                  />
                  <Route
                    path={routes.SITES_PATH}
                    render={() =>
                      env.library === 'production' ? (
                        <ProductionCharts.SitesComponent
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      ) : (
                        <TestingCharts.SitesComponent
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      )
                    }
                  />
                  <Route
                    path={routes.UTM_STAT_PATH}
                    render={() =>
                      env.library === 'production' ? (
                        <ProductionCharts.UTMPage
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      ) : (
                        <TestingCharts.UTMPage
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      )
                    }
                  />
                  <Route
                    path={routes.ADTYPE_STAT_PATH}
                    render={() =>
                      env.library === 'production' ? (
                        <ProductionCharts.AdTypeComponent
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      ) : (
                        <TestingCharts.AdTypeComponent
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      )
                    }
                  />
                  <Route
                    path={routes.VERSION_COMPARISON_STAT_PATH}
                    render={() =>
                      env.library === 'production' ? (
                        <ProductionCharts.VersionComparisonComponent
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      ) : (
                        <TestingCharts.VersionComparisonComponent
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                        />
                      )
                    }
                  />
                  <Route path={routes.SITES_LIST_PATH} component={SiteList} />
                  <Route path={routes.CONFIG_PATH} render={() => <ConfigIndexComponent />} />
                  <Route path={routes.ADUNIT_LIST_PATH} component={AdUnitList} />
                  <Route path={routes.ADUNIT_PATH} render={() => <AdUnitIndexComponent />} />
                  <Route
                    path={routes.ALERT_PATH}
                    render={() => <Alerts updateNewAlertCounter={updateNewAlertCounter} />}
                  />
                  <Route path={routes.ALERT_CONFIG_PATH} render={() => <AlertConfigIndexComponent />} />
                  <Route path={routes.ALERT_NOTIFICATION_PATH} render={() => <AlertNotificationIndexComponent />} />
                  <Route
                    path={routes.REPORTS_PATH}
                    render={() =>
                      env.library === 'production' ? (
                        <ProductionCharts.AuctionsReportScreen
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                          pageUrlEnabled={profile?.pageUrlEnabled}
                        />
                      ) : (
                        <TestingCharts.AuctionsReportScreen
                          token={`${loginStore.accessToken}`}
                          apiUrl={env.apiUrl}
                          profileCurrency={profile?.userCurrency}
                          pageUrlEnabled={profile?.pageUrlEnabled}
                        />
                      )
                    }
                  />
                  <Route path={routes.PROFILE_PATH} render={() => <ProfileScreen reloadProfile={loadProfile} />} />
                  <Route path={routes.PAYMENTS_PATH} component={PaymentsScreen} />
                  <Route path={routes.TERMS_OF_SERVICE_PATH} component={TermsPage} />
                  <Redirect from="/" to={routes.DASHBOARD_PATH} />
                </Switch>
              ) : (
                <Switch>
                  <Route path="/terms" component={TermsPage} />
                  <Route render={() => <ProfileScreen reloadProfile={loadProfile} />} />
                </Switch>
              )}
              {/* </Spin> */}
            </PageContent>
            <BottomNav />
          </MainContainer>
        </>
      )}
    </Body>
  )
})
